import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Navigation from '../components/navigation';
import ContactForm from '../components/ContactForm';
import SEO from '../components/seo';

import BlockContent from "@sanity/block-content-to-react"


const Coaches = ({ data, location }) => {
  const coaches = data.sanityCoaches.coach;


  const blockRenderer = ({ node, children }) => {
    switch (node.style) {
      case "h1":
        return (
          <div >
            <h1 >{children}</h1>
          </div>
        )
      case "h2":
        return (
          <div >
            <h2 >{children}</h2>
          </div>
        )
      case "h3":
        return (
          <div >
            <h3 >{children}</h3>
          </div>
        )
      case "h4":
        return (
          <div >
            <h4 >{children}</h4>
          </div>
        )
      case "h5":
        return (
          <div >
            <h5 >{children}</h5>
          </div>
        )
      case "h6":
        return (
          <div >
            <h6 >{children}</h6>
          </div>
        )
      case "blockquote":
        return (
          <div>
            <blockquote>{children}</blockquote>
          </div>
        )
      default:
        return (
          <div>
            <p>{children}</p>
          </div>
        )
    }
  }

  const coachesContainerStyles = css`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    /* width: 100%; */
    max-width: 750px;
    margin: var(--size-4) var(--size-4) 0;

    @media (min-width: 798px) {
      margin: var(--size-8) auto 0;
    }

    @media (min-width: 1048px) {
      margin: var(--size-10) auto 0;
    }

    /* > div {
      width: calc(45% - var(--size-8));
      flex-flow: 0 0 calc(45% - var(--size-8));
    } */
  `;

  const coachStyling = css`
    display: flex;
    flex-flow: row wrap;
    /* justify-content: space-between; */
    align-items: flex-start;
    margin-bottom: var(--size-10);

    @media (min-width: 900px) {
      margin-bottom: var(--size-11);
    }
  `;

  const coachImageStyling = css`
    width: 100%;
    flex: 1 0 auto;
    margin-bottom: var(--size-8);
  `;

  const CoachImagePadding = styled(Img)`
    > div:first-child {
      @media (max-width: 600px) {
        padding-bottom: ${(props) =>
          `${100 / props.sources[0].aspectRatio}% !important`};
      }
    }
  `;

  const coachTextStyling = css`
    width: 100%;
  `;

  return (
    <Layout>
      <SEO
        title='Meet the Coaches'
        description='Meet the coaches of Tri-Rivington'
      />
      <Navigation solid />
      <div css={coachesContainerStyles}>
        <h1
          css={css`
            margin-bottom: var(--size-8);
          `}
        >
          Meet the coaches
        </h1>
        {coaches.map((coach, index) => {

          
          const number = index;

          const imageSources = [
            {
              ...coach.coachImageSmall.asset.fluid,
              // aspectRatio: 4 / 3,
            },
            {
              ...coach.coachImageLarge.asset.fluid,
              media: `(min-width: 600px)`,
            },
          ];

          function checkNumber(number) {
            if (number % 2 === 0) return 'even';
            else return 'odd';
          }

          return (
            <div css={coachStyling} key={coach._key}>
              <div className={`${checkNumber(number)}`} css={coachImageStyling}>
                <CoachImagePadding
                  // fluid={{
                  //   ...coach.coachImageLargeScreen.fluid,
                  //   sizes: '(min-width: 1200px) 1680px, 200vw',
                  // }}
                  sources={imageSources}
                  fluid={imageSources}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
              <div css={coachTextStyling}>
                <div>
                  <h2>{coach.coachName}</h2>
                </div>
                <div>
                          <BlockContent
              blocks={coach._rawCoachBio}
              serializers={{
                types: { block: blockRenderer },
              }}
            />
            </div>
                <div>
                  <h3>Qualifications</h3>
    {coach.qualifications.map((qualification) =>{
     return (
       <ul>
       <li>{qualification}</li>
       </ul>
     )
    }
    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ContactForm location={location.pathname} />
    </Layout>
  );
};

export default Coaches;

export const pageQuery = graphql`
  query CoachesQuery {
    site {
      siteMetadata {
        title
      }
    }



    sanityCoaches(id: { eq: "-bb4b027e-9076-5228-87da-ec9f5ad86226" }) {
    coach {
      _key
      coachName
      coachImageLarge {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
            aspectRatio
          }
        }
      }
      coachImageSmall {
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid_noBase64
            aspectRatio
          }
        }
      }
      _rawCoachBio
      qualifications
    }
  }


  }
`;
